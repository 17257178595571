import 'jquery'
require("@rails/ujs").start()
import 'popper.js'
import 'bootstrap'
import 'application/application.scss'
import ahoy from "ahoy.js";

window.ahoy = ahoy

window.ahoy.configure({
  cookies: false,
  visitsUrl: "/ahoyyy/visits",
  eventsUrl: "/ahoyyy/events"
})

function is_url(str) {
  var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

function open_modal() {
  if (is_url($("#report_url").val())){
    $(".url-invalid").addClass("d-none")
    $('#emailModal').modal('show');
  }else{
    $(".url-invalid").removeClass("d-none")
  }
}

$( document ).ready(function() {
  $('.modal-open-button').click(function() {
    open_modal();
  })

  $('#accept').click(function() {
    if ($(this).is(':checked')) {
      $('#accept-button').removeAttr('disabled');
    } else {
      $('#accept-button').attr('disabled', 'disabled');
    }
  });

  $(".new-url").on('keyup', function (e) {
    if (e.keyCode === 13) open_modal();
  });

  $('.tumb-icon').click(function() {
    $(this).hide()
    $(".showcase-img-eb").css("background-image", "");
    $("#empfehlungsbund-video").removeClass("d-none")
    $("#empfehlungsbund-video").get(0).play()
  });
});
